<template>
  <v-app id="inspire">
    <v-app-bar fixed elevation="1" class="ml-0" color="white">
      <v-toolbar fixed color="white" elevation="0">
        <v-img src="@/assets/logoo.png" max-height="36" max-width="30"></v-img>

        <div class="black--text pl-3 mb-0">
          <h4 class="">DESA SINTUK</h4>
          <h6>SATU DATA KOTA PARIAMAN</h6>
        </div>
        <v-spacer></v-spacer>

        <v-btn
          class="ma-1 white--text hidden-sm-and-down"
          color="orange"
          v-for="link in itemslink"
          :to="link.to"
          :key="link.to"
          text
        >
          <h4 class="black--text">{{ link.judul }}</h4>
        </v-btn>
        <br />
        <v-btn
          small
          color="teal"
          to="/login"
          class="white--text ma-2 hidden-sm-and-down"
        >
          Login
        </v-btn>
      </v-toolbar>
    </v-app-bar>

    <v-main class="grey lighten-4 py-8"><router-view></router-view> </v-main>
    <v-card class="ma-2 hidden-lg-only">
      <v-card-text>
        <v-bottom-navigation class="hidden-lg-only" fixed>
          <v-btn
            v-for="link in itemslink"
            :to="link.to"
            :key="link.to"
            color="deep-purple accent-4"
            class="ma-3"
            text
          >
            <span class="mb-3">{{ link.judul }}</span>
            <v-icon class="mt-3">{{ link.icon }}</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-card-text>
    </v-card>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    drawer: null,
    itemslink: [
      {
        to: "/",
        judul: "Beranda",
        icon: "mdi-home",
      },
      {
        to: "/rumah-data",
        judul: "Rumah Data",
        icon: "mdi-database",
      },
      {
        to: "/statistik-data",
        judul: "Statistik",
        icon: "mdi-chart-bar",
      },
    ],
  }),
};
</script>
